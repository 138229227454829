.user-settings {
    z-index: 1;
    width: 425px;
    min-height: 335px;
    min-width: 300px;
    background-color: rgba(242,242,242,1);
    position: absolute;
    bottom: 12.5vh;
    left: 2.5%;
}

.user-settings-header {
    width: 100%;
    background-color: rgba(222,222,222,1);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10% 0 10% 0;
}


.user-settings-title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-settings-title {
    margin: 0;
}

.user-settings-options {
    overflow: auto;
    min-height: 425px;
    height: 30vh;
    padding: 0 3%;
}

.li-container {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    min-width: 300px;
}

.user-settings-options > ul > li:hover {
    background-color: rgba(173, 216, 230, 0.4);
    cursor: pointer;
}

.elem-arrow-img{
    transition: .3s !important;
    display: flex;
}

.user-settings-elem {
    white-space: nowrap;
    width: inherit;
    list-style-type: none;
    border-bottom: 1px solid gray;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5vh;
    transition: 0.4s;
}

.user-settings-elem:hover .elem-arrow-img {
    margin-left: 20px;
}

.option {
    margin-right: 16%;
    width: 50%;
}

.option-icon {
    margin-left: 5%;
    margin-right: 5%;
    width: 24px;
    height: 24px;
}

.settings-user-name {
    font-size: 12px;
}

.user-settings-btn {
    cursor: pointer;
    color: white;
    border: none;
    background-color: rgba(239, 125, 11, 1);
    border-radius: 3%/10%;
    width: 40%;
    height: 2.5rem;
    margin-bottom: 3%;
    transition: .3s;
}

.user-settings-input {
    background-color: #DEDEDE;
    border-radius: 8%/24%;
}

/* NotificationSettings  */
.pref {
    display: flex;
    gap: .5rem;
    flex-direction: column;
}

.pref-element {
    display: flex;
    align-items: center;

    margin-left: 4%;
}

/* Language */
.language-input-block {
    display: flex;
    align-items: center;
}

.language-elem:hover {
    background-color: rgba(173, 216, 230, 0.4);
    cursor: pointer;
}

.top-text {
    margin: 0;
    padding: 15px 0;
}

.language-label {
    align-self: center;
}

/* Account Preferences */
.language-format-block {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.time-zone-label {
    margin-right: 20%;
    white-space: nowrap;
}

.acc-pref-time-zone-container {
    display: flex;
    align-items: center;
}

.demo-simple-select-filled {
    margin-left: 200px;
}

.acc-pref-timezone-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

}

.account-pref-label {
    margin: 0;
}

.format {
    width: 27%;
}

/* Help */
.help-container {
    padding-top: 4%;
}

.back-arrow {
    position: absolute !important;
    top: 2%;
    left: 1%;
    cursor: pointer;
}

/* UserImg */

.user-settings-img {
    position: relative;
    display: flex;
    width: 90px;
    height: 90px;
    background-color: lightgray;
    border-radius: 50px;
}

.user-image{
    width: inherit;
    border-radius: inherit;
}

.user-edit{
    display: none;
    position: absolute;
    background: darkgray;
    width: inherit;
    height: inherit;
    opacity: 0.6;
    border-radius: inherit;
}

.user-edit-img{
    margin: auto;
    width: 50%;
}

.user-settings-img:hover .user-edit{
    display: flex;
    cursor: pointer;
}

.check-icon{
    position: absolute;
    right: 10%;
}



