.auth-page {
    width: 96%;
    height: 94vh;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3vh 2%;
    background-color: #f5f5f5;
    border-radius: 30px;
    box-shadow: 0 1.2px 3.6px rgba(0, 0, 0, 0.1), 0 6.4px 14.4px rgba(0, 0, 0, 0.13);
}

.backgroundImg {
    position: absolute;
}

.firstImg {
    top: 0;
    left: 16%;
}

.secondImg {
    width: 14%;
    top: 26%;
    left: 0;
}

.thirdImg {
    left: 18%;
    top: 36%;
}

.fourthImg {
    width: 3.5%;
    top: 61%;
    left: 32%;
}

.fifthImg {
    width: 26%;
    left: 20%;
    bottom: 0;
}

.sixthImg {
    width: 26%;
    top: 0;
    right: 0;
}

.seventhImg {
    width: 3%;
    left: 65%;
    top: 69%;
}

.eighthImg {
    width: 20%;
    bottom: 0;
    right: 0;
}


.atom_logo {
    position: absolute;
    width: 488px;
    top: 10%;

}

.footer {
    font-size: 1rem;
}
