.admin-panel {
    width: 100%;
    height: 100vh;
    background-color: rgba(246, 246, 246, 1);
}

.admin-atom-logo {
    cursor: pointer;
    width: 10%;
    margin-left: 4.2%;
    margin-top: 0.5%;
}

.admin-ui-header {
    min-width: 800px;
    margin: 1% 5%;
    display: flex;
    flex-direction: row;
    width: 90%;
}

.table-search {
    display: flex;
    width: 30%;
    justify-content: space-between;
    align-items: center;
    margin-right: 4rem;
}

.table-search-input {
    font-size: 1rem;
    border: none;
    outline: none;
    background-color: rgba(246, 246, 246, 1);
    width: 100%;
}

.table-search-icon {
    height: 20px;
    width: 24px;
}

.table-container {
    height: 80vh;
    width: 90vw;
    min-width: 1500px;
    margin: auto;
    overflow: auto;
    background-color: white;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.even-row {
    background-color: rgba(229, 229, 229, 0.6);
}

.li-users-list {
    align-items: center;
    padding-right: 5%;
    display: flex;
    justify-content: space-between;
}

.MuiDataGrid-root .MuiDataGrid-cell {
    white-space: normal !important;
    word-wrap: break-word !important;
}


.options-container {
    display: flex;
    justify-content: space-around;
    padding-top: 8px;
}

.header-buttons {
    display: flex;
    gap: 0.5rem;
}