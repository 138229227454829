.modal-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;
    position: absolute;
    z-index: 10;
    left: 35%;
    top: 25%;
    width: 30vw;
    height: 500px;
    min-width: 650px;
    background-color: rgba(250, 250, 250, 1);
    box-shadow: 0 15px 30px rgb(0 0 0 / 20%);
    backdrop-filter: blur(5px);
    outline: none;
}

.modal-container header {
    height: 45px;
    background: #fff;
    border-radius: 8px 8px 0 0;
    box-shadow: 0 1px 1px rgb(0 0 0 / 20%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
}

.modal-container footer {
    min-height: 45px;
    background: #fff;
    border-radius: 0 0 8px 8px;
    box-shadow: 0 -1px 1px rgb(0 0 0 / 20%);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10px;
}

.modal-search {
    font-family: 'montserrat';
    font-size: 0.9rem;
    color: #817F7F;
    border: none;
    border-bottom: 1px solid #636363;
    background: transparent;
    outline: none;
    width: 80%;
    height: 2rem;
    margin: 0 5px 20px 7.5% !important;
}

.delete-form {
    flex-grow: 1;
    width: 80%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    height: calc(100% - 90px);
}

.form-header {
    background-color: rgb(235,235,235);
}

.form-p {
    margin-left: 7%;
    margin-top: 0;
    padding-top: 16px;
}

.form-header-user-list {
    margin-left: 3%;
    overflow: auto;
    width: 85%;
}

.form-header-user-list::-webkit-scrollbar {
    height: .4em;
    width: .2em;
}

.form-header-user-list::-webkit-scrollbar-thumb {
    background-color: #294956;
    border-radius: 1rem;
}

.form-permissions {
    padding: 2% 5% 0 5%;
    background-color: rgba(250, 250, 250, 1);
    height: 300px;
}

.form-permissions-list {
    overflow: auto;
    height: 230px;
}

.form-permissions-list div {
    white-space: nowrap;
}

.form-permissions-list::-webkit-scrollbar {
    height: .4em;
    width: .2em;
}

.form-permissions-list::-webkit-scrollbar-thumb {
    background-color: #294956;
    border-radius: 1rem;
}

.form-input-btn-section {
    margin-top: 1%;
}

.form-search {
    border: none;
    outline: none;
    background-color: #DEDEDE;
    border-radius: 3%/20%;
    height: 1.7vh;
}

.form-cancel-btn {
    cursor: pointer;
    border: none;
    color: #636363;
    background-color: rgba(242, 242, 242, 0);
}
