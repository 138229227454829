.home-page {
    margin: 0;
    padding: 0;
    height: 100vh;
    width: 100vw;
    min-height: 720px;
    min-width: 1080px;
    background-color: rgba(29, 73, 86, 1);
    position: relative;
}

.home-page-background {
    position: absolute;
}

.background-circle {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.background-circle-img {
    height: 80vmin;
}

.background1 {
    width: 27%;
}

.background2 {
    width: 80%;
    right: 0;
}

.background3 {
    width: 25%;
    bottom: 0;
    right: 0;
}

.logout {
    bottom: 5vh;
    left: 3.5vh;
    position: absolute;
}

.logout-text {
    text-decoration: none;
    font-size: 2rem;
    color: white;
    font-family: Ubuntu;
}


.cogwheel {
    width: 5vh !important;
    height: 5vh !important;
    transition: .5s !important;
    color: rgb(242, 242, 242);
}

.cogwheel:hover {
    width: 5.5vh !important;
    height: 5.5vh !important;
    cursor: pointer !important;
}

.cogwheel-active {
    transform: rotate(360deg) !important;
}

.loader {
    height: 20vh;
    width: 17.44vh;
    animation-duration: 2s;
    animation-name: rotation;
    animation-iteration-count: infinite;
}

@keyframes rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }

}
