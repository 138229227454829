.login {
    width: 25%;
    height: 40%;
    border-radius: 50px;
    background-color: #EAE8E8;
    text-align: center;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 30%;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%);
}

.login-btn {
    padding: 0;
    align-items: center;
    display: flex;
    min-height: 30px;
    height: 63px;
    min-width: 200px;
    width: 70%;
    background: #4E85F7;
    color: #fff;
    border: none;
    border-radius: 5px;
}

.login-btn:hover {
    cursor: pointer;
}

.userImg {
    position: absolute;
    top: -57px;
    width: 115px;
}

.service-icon {
    height: 35px;
    background-color: #fff;
    margin: 2px;
    padding: 12px;
    border-radius: 5px;
}

.second-btn {
    background-color: #000;
}

.footer {
    position: absolute;
    font-size: 1.3rem;
    bottom: 0;
    width: 75%;
    transform: translateX(-50%);
    left: 50%;
}

.loginText {
    text-align: center;
    width: 100%;
    font-family: 'Product Sans', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: calc(11px + 6 * (100vw / 1280));
    line-height: 25px;
}

.outer-circle-buttons {
    position: absolute;
}

@media screen and (min-height: 1200px) {
    .login {
        height: 30%;
    }
}

@media screen and (min-height: 1600px) {
    .service-icon {
        height: 70%;
        margin-left: 2px;
    }

    .loginText {
        font-size: 34px;
    }

    .userImg {
        width: 20%;
        top: -80px;
    }

    .login-btn {
        height: 96px;
    }
}