.avatar-process-container {
    position: absolute;
    z-index: 10;
    left: 30%;
    top: 25%;
    width: 40vw;
    min-height: 500px;
    background-color: rgba(222, 222, 222, 1);
    box-shadow: 0 15px 30px rgb(0 0 0 / 20%);
    border-radius: 0.5rem;
    backdrop-filter: blur(5px);
    outline: none;
}

.avatar-img {
    display: flex;
    justify-content: center;
    margin-top: 5px;

}

.crop-img-container{
    display: flex;
    flex-direction: column;
}

.dropzone-area {
    margin: 15px 0;
    display: flex;
    width: 90%;
    height: 300px;
    align-items: center;
    justify-content: center;
    border: 1px dashed gray;
}

.img-preview {
    width: 150px;
    margin-top: 5px;
}

.processing-avatar-btns {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 2%;
}

.avatar-btn {
    cursor: pointer;
    color: white;
    border: none;
    border-radius: 3%/10%;
    width: 40%;
    height: 2.5rem;
    margin-bottom: 3%;
    transition: .3s;
}

.avatar-btn:hover {
    box-shadow: 0 15px 30px rgb(0 0 0 / 20%);
}

.accept-btn {
    background-color: #00ba00;
}

.cancel-btn {
    background-color: #ed2027;
}

.avatar-close-button {
    position: absolute;
    right: 10px;
    top: 10px;
    border: none;
    background: none;
    cursor: pointer;
}

.avatar-close-icon {
    width: 30px;
    transition: .3s;
}

.avatar-close-icon:hover{
    width: 32px;
}

.avatar-delete-btn {
    background: none;
    border: none;
    color: #0664ef;
    margin-left: 50px;
    margin-top: 12px;
    font-size: 16px;
    cursor: pointer;
}

.avatar-btns {
    display: inline-flex;
    gap: 1.5rem;
    float: right;
    margin-right: 20px;
    margin-top: 5px;
}

.avatar-bottom-btn {
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 3%/10%;
    transition: 0.3s;
    min-width: fit-content;
    float: right;
    margin-top: 2px;
    margin-right: 15px;

    background-color: #2c81ff;
    color: #fff;
}

.avatar-bottom-btn:hover{
    box-shadow: 0 3px 2px #294B57;
}

.avatar-bottom-btn:disabled{
    background-color: #7bacf3;
    pointer-events: none;
}
