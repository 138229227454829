.outer-circle-buttons {
    display: flex;
}

.block {
    margin-left: -49.5vh;
    width: 49.5vh;
    margin-top: -6vh;
    transform-origin: 100% 50%;
    transform: rotate(0deg);
    position: absolute;
    border-radius: 15%/30%;
}

.square {
    cursor: pointer;
    background: white;
    width: 22vh;
    height: 11vh;
    border-radius: inherit;
    border: none;
    padding: 0;
    transition: 0.3s;
}

.square:hover {
    width: 24vh;
    height: 12vh;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 3);
}

.inner-block {
    margin-left: -64.5vh;
    width: 25vh;
    margin-top: -3.3vh;
    transform-origin: 100% 50%;
    transform: rotate(0deg);
    position: absolute;
}

.inner-square {
    cursor: pointer;
    background: white;
    width: 6vh;
    height: 6vh;
    border-radius: 50%;
    border: none;
    margin: 0;
    padding: 0;
    transition: 0.3s
}

.inner-square:hover {
    width: 7vh;
    height: 7vh;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 3);
}

.inn-btn {
    width: inherit;
    height: inherit;
    border-radius: inherit;
}

.outer-btn {
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    margin: -1px 0 0 -1px;
    border-radius: inherit;
}

.learn-more {
    position: absolute;
    bottom: 8%;
    right: 4%;
    font-weight: 700;
    border: 0.13rem solid;
    border-radius: 8%/45%;
    width: 50%;
    font-size: 1.3vh;
    text-decoration: none;
    transition: 0.3s;
}

.learn-more:hover {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    bottom: 9%;
    right: 3%;
}
